import React, { Fragment, useState } from 'react'
import classNames from 'classnames'
import { CalendarDots, List, Question, UserCircle } from '@phosphor-icons/react'
import { menu } from 'data/menu'

export default function Toolbar() {

    const [openMenu, setOpenMenu] = useState(false)

    const MenuClasses = classNames({
        "fixed w-full z-100 bottom-90 left-0 drop-shadow-toolbar transition-all duration-100 ease-linear md:hidden": true,
        "max-h-0": !openMenu,
        "max-h-300": openMenu,
    })

    return (
        <Fragment>
            <div className='w-full bg-dark px-5 fixed bottom-0 left-0 z-200 md:hidden'>
                <div className='w-full h-90 flex items-center justify-between'>
                    <a className='flex items-center bg-white bg-opacity-10 rounded-3xl py-3 px-8 pr-8' href="/ricerca">
                        <CalendarDots className="h-6 w-6 mr-2 fill-white"/>
                        <div className='text-lg font-medium text-white'>Prenota</div>
                    </a>
                    <a href="/cerca-prenotazioni" className='flex items-center rounded-2xl py-2 px-3'>
                        <Question className="h-7 w-7 fill-white"/>
                    </a>
                    <a href="/profilo/prenotazioni" className='flex items-center rounded-2xl py-2 px-3'>
                        <UserCircle className="h-7 w-7 fill-white"/>
                    </a>
                    <div onClick={() => setOpenMenu(!openMenu)} className='flex items-center rounded-2xl py-2 px-3'>
                        <List className="h-7 w-7 fill-white"/>
                    </div>
                </div>
            </div>

            <div className={MenuClasses}>
                <div className="w-full h-full bg-dark pt-8 pb-4 px-8 left-0 box-border top-0">
                    <ul>
                        {menu?.map((item, index) => {
                            return (
                                <li key={index}>
                                    <a href={item.href} className='text-white text-lg py-1 block text-right'>
                                        {item.label}
                                    </a>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </Fragment>
    )
}
